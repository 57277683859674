.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  width: 1rem;
  height: 1rem;

}
.slick-prev:before{
  color: #3F6BAA !important; 

} 
.slick-next:before {
  color: #3F6BAA !important; 

}
.slick-slider{
  line-height: 0;
}
.slick-prev {
  left: 1rem;
}

.slick-next {
  right: 1rem;
}

.arrows {
  font-size: 2rem;
}

.flip-card {
  background-color: transparent;
  width: 350px;
  height: 500px;
  perspective: 1000px;
  font-family: sans-serif;
  padding-inline: 15px;
  padding-block: 15px;
}


.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 1rem;
}


.flip-card-back {
  transform: rotateY(180deg);
}